<template>
  <div>
    <a-modal :visible="visible" title="批量领料" :width="1000" :confirmLoading="loading" @cancel="cancel" @ok="confirm">
      <a-table rowKey="id" size="small" :columns="columns" :dataSource="dataItems" :pagination="false">
        <template slot="stock_out_quantity" slot-scope="value, item">
          <a-input-number v-model="item.stock_out_quantity" size="small" :min="0" />
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { productionOrderBatchStockOut } from "@/api/production";

export default {
  props: ["visible", "productionOrderItem", "materialItems"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      loading: false,
      dataItems: {},
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: "零件编号",
          dataIndex: "goods_number",
          customRender: (_, item) => item.goods_item.number,
        },
        {
          title: "零件名称",
          dataIndex: "goods_name",
          customRender: (_, item) => item.goods_item.name,
        },
        {
          title: "规格",
          dataIndex: "spec",
          customRender: (_, item) => item.goods_item.spec,
        },
        {
          title: "图号",
          dataIndex: "drawing_number",
          customRender: (_, item) => item.goods_item.drawing_number,
        },
        {
          title: "需求数量",
          dataIndex: "quantity",
          customRender: (value) => value * this.productionOrderItem.total_quantity,
        },
        {
          title: "库存数量",
          dataIndex: "inventory_quantity",
        },
        {
          title: "分类",
          dataIndex: "category_name",
          customRender: (_, item) => item.goods_item.category_name,
        },
        {
          title: "领料数量",
          dataIndex: "stock_out_quantity",
          scopedSlots: { customRender: "stock_out_quantity" },
        },
      ],
    };
  },
  methods: {
    confirm() {
      const dataItems = this.dataItems.map((item) => ({ goods: item.goods, stock_out_quantity: item.stock_out_quantity }));

      productionOrderBatchStockOut(this.productionOrderItem.id, dataItems)
        .then((data) => {
          this.$message.success("生产领料已申请");
          this.cancel();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
  watch: {
    visible(status) {
      if (status) {
        this.dataItems = this.materialItems.map((item) => ({
          ...item,
          stock_out_quantity: item.quantity * this.productionOrderItem.total_quantity,
        }));
      }
    },
  },
};
</script>

<style scoped></style>
